import { defineComponent, reactive } from "vue";
import { onConfirmReplaceClick, onCancelReplaceClick, replaceCompany, replaceOrder, afterSaleRule, } from "./EditParams";
export default defineComponent({
    setup() {
        const ruleForm = reactive({
            company: "",
            logistices: "",
        });
        const rules = {
            company: [
                {
                    required: true,
                    message: "请输入快递公司名称",
                    trigger: "blur",
                },
            ],
            logistices: [
                {
                    required: true,
                    message: "请输入快递单号",
                    trigger: "blur",
                },
            ],
        };
        const handleCompanyChange = (e) => {
            replaceCompany.value = e;
        };
        const handleLogisticsChange = (e) => {
            replaceOrder.value = e;
        };
        return {
            afterSaleRule,
            ruleForm,
            rules,
            onConfirmReplaceClick,
            onCancelReplaceClick,
            handleCompanyChange,
            handleLogisticsChange,
        };
    },
});

import { RefundType } from '@/common/enum';
import { ElMessage } from 'element-plus';
import { defineComponent, ref, watch } from 'vue';
export default defineComponent({
    props: {
        payPrice: {
            type: Number,
            default() {
                return 0;
            },
        },
    },
    setup(props) {
        const refundType = ref(RefundType.Radio);
        const refundRatio = ref(100);
        const refundMoney = ref(props.payPrice);
        watch(refundType, () => {
            refundRatio.value = 100;
            refundMoney.value = props.payPrice;
        });
        watch(refundRatio, () => {
            if (refundType.value == RefundType.Radio) {
                refundMoney.value =
                    Math.ceil(props.payPrice * refundRatio.value) / 100;
            }
        });
        watch(refundMoney, () => {
            if (refundType.value == RefundType.Amount) {
                refundRatio.value =
                    Math.ceil(refundMoney.value / props.payPrice * 10000) / 100;
            }
        });
        const reFundAmountValidate = () => {
            if (refundMoney.value.length == 0) {
                ElMessage.warning('退款金额不能为空');
                return false;
            }
            if (refundMoney.value < 0.01) {
                ElMessage.warning('退款金额不能小于0.01元');
                return false;
            }
            if (refundMoney.value > props.payPrice) {
                ElMessage.warning('退款金额不能大于用户支付金额');
                return false;
            }
            if ((refundMoney.value + '').split('.')[1]?.length > 2) {
                ElMessage.warning('退款金额最多只支持2位小数');
                return false;
            }
            return true;
        };
        return {
            refundType,
            refundRatio,
            refundMoney,
            RefundType,
            reFundAmountValidate,
        };
    },
});

import { defineComponent } from "vue";
import { recipientAddress, onConfirmReturnExchangeClick, onCancelReplaceClick, } from "./EditParams";
export default defineComponent({
    setup() {
        return {
            onConfirmReturnExchangeClick,
            onCancelReplaceClick,
            recipientAddress,
        };
    },
});

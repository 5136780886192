import { reactive, ref } from "vue";
import { OrderType, sortDirection } from "@/common/enum";
import TableSortClick from "@/views/Common/SortTable";
import { ScrollToTop } from "@/common/utils";
import { FormatterDateTime } from "@/common/formatter";
import { GetAfterSaleList, GetAfterSaleArgs, DeliverResponse } from "@/api/AfterSaleApi";
export default function () {
    const { setCurrentSort, headerSortClick } = TableSortClick();
    setCurrentSort({
        prop: "afterServiceTime",
    });
    const showHistoryDlg = ref(false);
    const selectRow = reactive(new DeliverResponse());
    const datas = reactive(new Array());
    const totalCount = ref(0);
    const queryArgs = reactive(new GetAfterSaleArgs());
    const headerPurchaseTime = ref("");
    const headerAferSaleTime = ref("");
    queryArgs.dealStatus = 2;
    const changeTime = (val, isPurchase) => {
        if (isPurchase) {
            queryArgs.purchaseTimeStart = val && FormatterDateTime(val[0], "yyyy-MM-dd hh:mm:ss") || "";
            queryArgs.purchaseTimeEnd = val && FormatterDateTime(val[1], "yyyy-MM-dd hh:mm:ss") || "";
        }
        else {
            queryArgs.afterSalesTimeStart = val && FormatterDateTime(val[0], "yyyy-MM-dd hh:mm:ss") || "";
            queryArgs.afterSalesTimeEnd = val && FormatterDateTime(val[1], "yyyy-MM-dd hh:mm:ss") || "";
        }
    };
    const queryData = async () => {
        queryArgs.orderType = OrderType.PurchaseTime;
        const args = {
            ...queryArgs,
            orderId: +(queryArgs.orderId || '')
        };
        const res = await GetAfterSaleList(args);
        if (res.errno == 0) {
            datas.splice(0, datas.length);
            const list = res.data.list;
            list.forEach(item => {
                datas.push(item);
            });
            totalCount.value = res.data.total;
        }
        ScrollToTop();
    };
    const formatAfterSaleTime = (row) => {
        return FormatterDateTime(row.afterServiceTime, "yyyy-MM-dd hh:mm:ss") || '-';
    };
    const formatPurchaseTime = (row) => {
        return FormatterDateTime(row.purchaseTime, "yyyy-MM-dd hh:mm:ss") || '-';
    };
    const headerClick = async (column) => {
        if (await headerSortClick(column)) {
            queryArgs.orderType = column.prop == 'purchaseTime' ? OrderType.AfterSalePurchaseTime : OrderType.AfterSaleApplyTime;
            queryArgs.sortDirection = sortDirection[column.order] * 1;
            queryArgs.page = 1;
            queryData();
        }
    };
    const goHistory = (row) => {
        showHistoryDlg.value = true;
        selectRow.applyId = row.applyId;
    };
    return {
        datas,
        totalCount,
        queryData,
        queryArgs,
        showHistoryDlg,
        selectRow,
        headerPurchaseTime,
        headerAferSaleTime,
        changeTime,
        formatAfterSaleTime,
        formatPurchaseTime,
        headerClick,
        goHistory
    };
}

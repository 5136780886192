import { defineComponent } from "vue";
import RefundAmountComp from './PartRefundAmount.vue';
import { isParticalRefund, refundAmountRef, onRejectReceived, onConfirmReturnClick, replaceCompany, replaceOrder, payPrice, } from "./EditParams";
export default defineComponent({
    components: {
        RefundAmountComp,
    },
    setup() {
        return {
            isParticalRefund,
            refundAmountRef,
            onConfirmReturnClick,
            onRejectReceived,
            payPrice,
            replaceCompany,
            replaceOrder,
        };
    },
});

import { defineComponent, reactive } from "vue";
import { onRejectClick, onCancelReplaceClick, phoneNumber, rejectReason, afterSaleRule, } from "./EditParams";
export default defineComponent({
    setup() {
        const ruleForm = reactive({
            phone: phoneNumber.value,
            reason: rejectReason.value,
        });
        const rules = {
            reason: [
                {
                    required: true,
                    message: "请输入拒绝原因",
                    trigger: "blur",
                },
            ],
            phone: [
                {
                    required: true,
                    message: "请输入手机号",
                    trigger: "blur",
                },
            ],
        };
        const handleReasonChange = (e) => {
            ruleForm.reason = e;
        };
        const handlePhoneChange = (e) => {
            ruleForm.phone = e;
        };
        return {
            ruleForm,
            rules,
            afterSaleRule,
            onRejectClick,
            onCancelReplaceClick,
            phoneNumber,
            rejectReason,
            handleReasonChange,
            handlePhoneChange,
        };
    },
});

import { defineComponent, onMounted, ref } from "vue";
import { BreadCrumb } from "@/services/BreadCrumb/BreadCrumb";
import BreadCrumbService from "@/services/BreadCrumb/BreadCrumbService";
import Pending from "./Pending/Pending.vue";
import Dealed from "./Dealed/Dealed.vue";
export default defineComponent({
    name: "AfterSale",
    components: {
        Pending,
        Dealed,
    },
    setup() {
        onMounted(() => {
            BreadCrumbService.ClearBreadCrumb();
            BreadCrumbService.InsertBreadCrumb(new BreadCrumb("1", "", "售后处理"));
            BreadCrumbService.InsertBreadCrumb(new BreadCrumb("1-1", "", "售后待处理"));
        });
        let tabs = [
            {
                id: "tab2",
                title: "售后待处理",
                name: "Pending",
                count: 0,
            },
            {
                id: "tab1",
                title: "售后已处理",
                name: "Dealed",
                count: 0,
            },
        ];
        let currentCom = tabs[0];
        let activeTab = ref(currentCom.name);
        const changeTab = (tab) => {
            currentCom = tabs[tab.index];
            activeTab.value = currentCom.name;
            BreadCrumbService.ClearBreadCrumb();
            BreadCrumbService.InsertBreadCrumb(new BreadCrumb("1", "", "售后处理"));
            BreadCrumbService.InsertBreadCrumb(new BreadCrumb("1-1", "", currentCom.title));
        };
        return {
            tabs,
            currentCom,
            activeTab,
            changeTab
        };
    },
});

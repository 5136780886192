import { GetAfterSaleHistory } from "@/api/AfterSaleApi";
import { defineComponent, onMounted, reactive } from "vue";
export default defineComponent({
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const datas = reactive(new Array());
        onMounted(() => {
            GetAfterSaleHistory({ applyId: props.data.applyId }).then((res) => {
                if (res.errno == 0) {
                    let result = res.data;
                    result.forEach((i) => {
                        datas.push(i);
                    });
                }
            });
        });
        const goDetail = (imgStr) => {
            window.open(imgStr);
        };
        const getImages = (val) => {
            return val.split(",");
        };
        return {
            datas,
            goDetail,
            getImages,
        };
    },
});

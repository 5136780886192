import { isAgreeAfterSale, UploadLogisticsNo, AfterSaleReceived, LookLogistics, ParticalRefund } from "@/api/AfterSaleApi";
import { ShopAfterServiceType, AfterSaleResult, AfterScaleReceivedType } from "@/common/enum";
import { ElMessageBox, ElMessage } from "element-plus";
import { ref } from "vue";
import { queryData } from '../Pending';
export const refundAmountRef = ref(null);
export const afterSaleRule = ref(null);
export const showDlg = ref(false);
export const dlgTitle = ref("");
export const componentName = ref("");
export const afterServiceType = ref(ShopAfterServiceType.ExchangeGoods);
export const payPrice = ref(0);
export const isParticalRefund = ref(false); // 是否部分退款
export const refundAmount = ref(0); // 退款金额
const afterServiceId = ref();
// 联系方式
export const phoneNumber = ref("");
// 拒绝原因
export const rejectReason = ref();
export const rejectForm = ref(null);
// 物流信息 - 物流公司
export const replaceCompany = ref("");
// 物流信息 - 物流单号
export const replaceOrder = ref("");
// 是否同意售后申请
function isAgreeAfterSaleApply(handleResult, optionReason = '', merchantAddress = '') {
    const args = {
        applyId: afterServiceId.value,
        phoneNumber: phoneNumber.value,
        handleResult,
        optionReason,
        merchantAddress,
        refundAmount: refundAmount.value
    };
    if (!handleResult)
        delete args.handleResult;
    if (!optionReason)
        delete args.optionReason;
    if (!merchantAddress)
        delete args.merchantAddress;
    isAgreeAfterSale(args).then(() => {
        showDlg.value = false;
        ElMessage({
            type: 'success',
            message: '处理成功',
        });
        queryData();
    });
}
function ReConfirmRefund() {
    ElMessageBox.confirm('是否同意用户申请退款', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
    })
        .then(() => {
        isAgreeAfterSaleApply(AfterSaleResult.Agree);
    })
        .catch(() => {
        ElMessage({
            type: 'info',
            message: '已取消',
        });
    });
}
export async function clickOk(row) {
    afterServiceType.value = row.afterSalesType || ShopAfterServiceType.ExchangeGoods;
    afterServiceId.value = row.applyId;
    payPrice.value = row.payPrice || 0;
    refundAmount.value = row.payPrice || 0;
    payPrice.value = row.payPrice || 0;
    phoneNumber.value = "";
    // if (ShopAfterServiceType.Refund == row.afterSalesType) {
    // if (row.deliverStatus == ShipmentStatus.Pendding) {
    // ReConfirmRefund()
    // return
    // }
    // if (row.deliverStatus == ShipmentStatus.Shippenf || row.deliverStatus == ShipmentStatus.NoShippingRequired) {
    // showDlg.value = await ParticalRefundCheck(row.id)
    // if (!showDlg.value) {
    //     ReConfirmRefund()
    //     return
    // }
    // dlgTitle.value = "退款"
    // componentName.value = 'ConfirmRefundAmount'
    // return
    // }
    // return;
    // }
    // 退款
    if (ShopAfterServiceType.Refund == row.afterSalesType) {
        ReConfirmRefund();
        return;
    }
    showDlg.value = true;
    recipientAddress.value = "";
    // if (row.afterSalesType == ShopAfterServiceType.ExchangeGoods) {
    //     dlgTitle.value = "换货"
    //     componentName.value = "ConfirmReturnExchang";
    // }
    // 退货
    if (row.afterSalesType == ShopAfterServiceType.ReturnGoods) {
        dlgTitle.value = "退货";
        componentName.value = "ConfirmReturnExchang";
    }
}
// 已发货-申请退款-部分退款
export function partRefund() {
    if (refundAmountRef.value.reFundAmountValidate()) {
        refundAmount.value = refundAmountRef.value.refundMoney;
        isAgreeAfterSaleApply(AfterSaleResult.Agree);
    }
}
// 商家收获地址
export const recipientAddress = ref("");
// 换货
export function onConfirmReturnExchangeClick() {
    isAgreeAfterSaleApply(AfterSaleResult.Agree, '', recipientAddress.value);
}
export function clickReject(row) {
    afterServiceId.value = row.applyId;
    showDlg.value = true;
    phoneNumber.value = "";
    rejectReason.value = "";
    componentName.value = "ConfirmReject";
    payPrice.value = row.payPrice || 0;
    refundAmount.value = row.payPrice || 0;
    if (ShopAfterServiceType.Refund == row.afterSalesType) {
        dlgTitle.value = "拒绝退款";
    }
    // if (row.afterSalesType == ShopAfterServiceType.ExchangeGoods) {
    //     dlgTitle.value = "拒绝换货"
    // }
    if (row.afterSalesType == ShopAfterServiceType.ReturnGoods) {
        dlgTitle.value = "拒绝退货";
    }
}
// 查看物流
export const clickCheckLogistics = async (row) => {
    afterServiceId.value = row.applyId;
    showDlg.value = true;
    componentName.value = "ConfirmLogistice";
    payPrice.value = row.payPrice || 0;
    refundAmount.value = row.payPrice || 0;
    // isParticalRefund.value = await ParticalRefundCheck(row.id)
    LookLogistics({ applyId: row.applyId }).then((res) => {
        replaceCompany.value = res.data.expressName;
        replaceOrder.value = res.data.expressNo;
        // if (row.afterSalesType == ShopAfterServiceType.ExchangeGoods) {
        //     dlgTitle.value = "查看物流 - 换货";
        //     rejectType.value = AfterScaleReceivedType.Exchange;
        // }
        if (row.afterSalesType == ShopAfterServiceType.ReturnGoods) {
            dlgTitle.value = "查看物流 - 退货";
            rejectType.value = AfterScaleReceivedType.Received;
        }
    });
};
// 上传快递单号 ConfirmUploadNo
export const clickUploadNo = (row) => {
    afterServiceId.value = row.applyId;
    dlgTitle.value = "上传快递单号";
    showDlg.value = true;
    replaceCompany.value = "";
    replaceOrder.value = "";
    componentName.value = "ConfirmUploadNo";
};
// 拒绝
export function onRejectClick() {
    afterSaleRule.value.validate((valid) => {
        if (valid) {
            // if (afterServiceType.value == ShopAfterServiceType.ExchangeGoods) {
            // if (rejectType.value == AfterScaleReceivedType.Normal) {3123
            // 是否同意售后申请
            isAgreeAfterSaleApply(AfterSaleResult.Reject, rejectReason.value);
            // } else {
            //     // 售后收货处理
            //     AfterSaleReceived({ handleResult: AfterSaleResult.Reject, optionReason: rejectReason.value, phoneNumber: phoneNumber.value }).then(() => {
            //         showDlg.value = false;
            //         queryData();
            //     })
            // }
            // }
        }
    });
}
// 查看物流确定已收到退货物流
const rejectType = ref(AfterScaleReceivedType.Normal);
function AfterSaleReceivedFun(handleResult, optionReason = "") {
    const args = {
        afterServiceId: afterServiceId.value,
        phoneNumber: phoneNumber.value,
        handleResult,
        optionReason,
    };
    if (!handleResult)
        delete args.handleResult;
    if (!optionReason)
        delete args.optionReason;
    AfterSaleReceived({ applyId: afterServiceId.value, handleResult, refundAmount: refundAmount.value }).then(() => {
        ElMessage.success("处理成功");
        showDlg.value = false;
        queryData();
    });
}
// 部分退款校验
export function ParticalRefundCheck(id) {
    return ParticalRefund({ id }).then((res) => {
        return res.resultData;
    });
}
export function onConfirmReturnClick() {
    if (isParticalRefund.value) {
        if (refundAmountRef.value.reFundAmountValidate()) {
            refundAmount.value = refundAmountRef.value.refundMoney;
            AfterSaleReceivedFun(AfterSaleResult.Agree);
        }
        return;
    }
    AfterSaleReceivedFun(AfterSaleResult.Agree);
}
// 查看物流 - 拒绝收货
export function onRejectReceived() {
    showDlg.value = true;
    componentName.value = "ConfirmReject";
    dlgTitle.value = "拒绝收货";
    rejectReason.value = "";
    phoneNumber.value = "";
}
// 上传物流单号
export function onConfirmReplaceClick() {
    afterSaleRule.value.validate((valid) => {
        if (valid) {
            const args = {
                afterServiceId: afterServiceId.value,
                logisticsCompany: replaceCompany.value,
                logisticsOrder: replaceOrder.value
            };
            UploadLogisticsNo(args).then(res => {
                if (res.isSuccess) {
                    ElMessage.success("处理成功");
                    showDlg.value = false;
                    queryData();
                }
            });
        }
    });
}
// 取消 dialog
export function onCancelReplaceClick() {
    showDlg.value = false;
}

import { defineComponent, onMounted } from 'vue';
import { datas, totalCount, queryArgs, queryData, headerClick, changeTime, changeAfterSaleTime, formatAfterSaleTime, formatPurchaseTime, clickAfterSalceImgs, showImagePre, AfterSalceImgs, headerPurchaseTime, headerAfterSaleTime } from './Pending';
import { TranslateafterServiceType, TranslateShipmentStatus, TranslateProductType, TranslateShopAfterSaleStatus, FormatterText } from '@/common/formatter';
import { showDlg, dlgTitle, clickOk, componentName, clickReject, clickCheckLogistics, clickUploadNo, payPrice, } from './EditReturn/EditParams';
import '@/commonStyle/dlg.css';
import ConfirmReturnExchang from './EditReturn/ConfirmReturnExchang.vue';
import ConfirmReject from './EditReturn/ConfirmReject.vue';
import ConfirmLogistice from './EditReturn/ConfirmLogistics.vue';
import ConfirmUploadNo from './EditReturn/ConfirmUploadNo.vue';
import ConfirmRefundAmount from './EditReturn/RefundAmount.vue';
import ImagePre from './EditReturn/ImagePre.vue';
import TableSortClick from '@/views/Common/SortTable';
import { beforeToday } from '@/common/utils';
import { ShopAfterServiceType, ShopAfterSaleStatus, ProductType, } from '@/common/enum';
import OrderInfoCmp from '@/components/orderInfo/OrderInfo.vue';
export default defineComponent({
    name: 'ReturnTable',
    components: {
        ConfirmReject,
        ConfirmReturnExchang,
        ConfirmLogistice,
        ConfirmUploadNo,
        ConfirmRefundAmount,
        ImagePre,
        OrderInfoCmp,
    },
    setup() {
        const { tableRef, setCurrentSort, currentSort } = TableSortClick();
        onMounted(() => {
            queryData();
            setCurrentSort({
                prop: 'afterServiceTime',
            });
        });
        const query = () => {
            queryArgs.page = 1;
            queryData();
        };
        const handleCurrentChange = (val) => {
            queryArgs.page = val;
            queryData();
        };
        const handleSizeChange = (val) => {
            queryArgs.pageSize = val;
            queryData();
        };
        const defaultTimeRange = [
            new Date(0, 0, 0, 0, 0, 0),
            new Date(0, 0, 0, 23, 59, 59),
        ];
        return {
            payPrice,
            defaultTimeRange,
            showDlg,
            datas,
            totalCount,
            queryArgs,
            queryData,
            query,
            handleCurrentChange,
            handleSizeChange,
            clickOk,
            clickReject,
            componentName,
            dlgTitle,
            headerClick,
            currentSort,
            tableRef,
            beforeToday,
            ShopAfterServiceType,
            headerAfterSaleTime,
            ShopAfterSaleStatus,
            showImagePre,
            AfterSalceImgs,
            headerPurchaseTime,
            changeTime,
            TranslateafterServiceType,
            TranslateShopAfterSaleStatus,
            FormatterText,
            changeAfterSaleTime,
            clickCheckLogistics,
            clickUploadNo,
            clickAfterSalceImgs,
            TranslateShipmentStatus,
            formatAfterSaleTime,
            formatPurchaseTime,
            TranslateProductType,
            ProductType,
        };
    },
});

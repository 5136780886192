import { defineComponent } from 'vue';
import { onCancelReplaceClick, partRefund, refundAmountRef } from './EditParams';
import RefundAmountComp from './PartRefundAmount.vue';
export default defineComponent({
    components: {
        RefundAmountComp,
    },
    props: {
        payPrice: {
            type: Number,
            default() {
                return 0;
            },
        },
    },
    setup(props) {
        return {
            refundAmountRef,
            partRefund,
            onCancelReplaceClick,
        };
    },
});

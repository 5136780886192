import { defineComponent, onMounted } from "vue";
import OrderInfoCmp from "@/components/orderInfo/OrderInfo.vue";
import DealFunc from "./Dealed";
import { TranslateafterServiceType, TranslateShipmentStatus, TranslateProductType, TranslateShopAfterSaleStatus, FormatterText } from "@/common/formatter";
import TableSortClick from "@/views/Common/SortTable";
import { beforeToday } from "@/common/utils";
import { ShopAfterServiceType, ShopAfterServiceStatus, ProductType, } from "@/common/enum";
import AfterSaleHistory from "@/components/afterSaleHistory/index.vue";
export default defineComponent({
    name: "ReturnTable",
    components: { OrderInfoCmp, AfterSaleHistory },
    setup() {
        const { tableRef, setCurrentSort, currentSort } = TableSortClick();
        const { datas, totalCount, goHistory, queryData, queryArgs, changeTime, formatAfterSaleTime, formatPurchaseTime, headerClick, showHistoryDlg, selectRow, headerPurchaseTime, headerAferSaleTime } = DealFunc();
        onMounted(() => {
            queryData();
            setCurrentSort({
                prop: "afterServiceTime",
            });
        });
        const query = () => {
            queryArgs.page = 1;
            queryData();
        };
        const handleCurrentChange = (val) => {
            queryArgs.page = val;
            queryData();
        };
        const handleSizeChange = (val) => {
            queryArgs.pageSize = val;
            queryData();
        };
        const defaultTimeRange = [
            new Date(0, 0, 0, 0, 0, 0),
            new Date(0, 0, 0, 23, 59, 59),
        ];
        return {
            datas,
            totalCount,
            queryArgs,
            queryData,
            goHistory,
            query,
            handleCurrentChange,
            handleSizeChange,
            formatAfterSaleTime,
            formatPurchaseTime,
            headerClick,
            currentSort,
            tableRef,
            beforeToday,
            headerPurchaseTime,
            changeTime,
            defaultTimeRange,
            ShopAfterServiceType,
            ShopAfterServiceStatus,
            headerAferSaleTime,
            TranslateShopAfterSaleStatus,
            FormatterText,
            TranslateafterServiceType,
            TranslateShipmentStatus,
            TranslateProductType,
            ProductType,
            showHistoryDlg,
            selectRow,
        };
    },
});

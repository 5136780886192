import { Get, Post, PlatPost, PlatGet } from './Request';
import { ShopAfterServiceType, ShopAfterServiceStatus, ProductType } from '@/common/enum';
import ShopService from '@/services/Shop/ShopService';
// 请求售后列表时候的参数
export class GetAfterSaleArgs {
    constructor() {
        this.afterServiceId = "";
        this.afterSalesTimeEnd = "";
        this.afterSalesTimeStart = "";
        this.afterServiceType = ShopAfterServiceType.All; // 售后类型
        this.handleStatus = ShopAfterServiceStatus.All; // 全部
        this.page = 1;
        this.pageSize = 10;
        this.productName = "";
        this.purchaseTimeEnd = "";
        this.purchaseTimeStart = "";
        this.recipientName = "";
        this.recipientPhone = "";
        this.sortDirection = 0;
        this.transactionOrder = "";
        this.merchantId = ShopService.GetCurrentShop().merchantId;
        this.productType = ProductType.All;
    }
}
// 售后列表包含的字段
export class DeliverResponse {
}
export class HistoryItem {
}
// 售后订单列表
export function GetAfterSaleList(data) {
    return PlatPost("/backend/shop/aftersales/list", data);
}
// 同意售后申请处理
export function isAgreeAfterSale(data) {
    return PlatPost("/backend/shop/aftersales/handleApply", data);
}
// 售后换货处理 - 上传物流单号
export function UploadLogisticsNo(data) {
    return Post("/afterService/handleExchangeDeliver", data);
}
// 售后收获处理
export function AfterSaleReceived(data) {
    return PlatPost("/backend/shop/aftersales/handleReturnGoods", data);
}
// 售后 - 查看物流信息
export function LookLogistics(data) {
    return PlatGet(`/backend/shop/aftersales/queryReturnExpress/${data.applyId}`, data);
}
// 售后 - 部分退款校验
export function ParticalRefund(data) {
    return Get(`/afterService/partialRefundCheck?id=${data.id}`, data);
}
// 售后 - 协商历史
export function GetAfterSaleHistory(data) {
    return PlatPost('/backend/shop/aftersales/history', data);
}

import { reactive, ref } from "vue";
import { OrderType, ShopAfterServiceType, sortDirection, ProductType } from "@/common/enum";
import TableSortClick from "@/views/Common/SortTable";
import { ScrollToTop } from "@/common/utils";
import { FormatterDateTime } from "@/common/formatter";
import { GetAfterSaleList, GetAfterSaleArgs } from "@/api/AfterSaleApi";
import { onTipsCountChanged } from "@/utils/eventBusConst";
import eventBus from "@/utils/eventBus";
import ShopService from "@/services/Shop/ShopService";
const { setCurrentSort, headerSortClick } = TableSortClick();
setCurrentSort({
    prop: "afterServiceTime",
});
export const datas = reactive(new Array());
export const totalCount = ref(0);
export const pageSize = ref(20);
export const page = ref(1);
export const headerPurchaseTime = ref('');
export const headerAfterSaleTime = ref('');
export const queryArgs = reactive(new GetAfterSaleArgs());
queryArgs.afterServiceId = "";
queryArgs.afterSalesTimeStart = "";
queryArgs.afterSalesTimeEnd = "";
queryArgs.afterServiceType = ShopAfterServiceType.All;
queryArgs.page = 1;
queryArgs.pageSize = 10;
queryArgs.productName = "";
queryArgs.purchaseTimeEnd = "";
queryArgs.purchaseTimeStart = "";
queryArgs.recipientName = "";
queryArgs.recipientPhone = "";
queryArgs.sortDirection = 0;
queryArgs.orderId = '';
queryArgs.merchantId = ShopService.GetCurrentShop().merchantId;
queryArgs.merchantId = 0;
queryArgs.orderType = OrderType.AfterSaleApplyTime;
queryArgs.productType = ProductType.All;
queryArgs.dealStatus = 1;
export async function queryData() {
    const args = {
        ...queryArgs,
        orderId: +(queryArgs.orderId || '')
    };
    const res = await GetAfterSaleList(args);
    if (res.errno == 0) {
        datas.splice(0, datas.length);
        const list = res.data.list || [];
        list.forEach(item => {
            datas.push(item);
        });
        totalCount.value = res.data.total;
        eventBus.emit(onTipsCountChanged);
    }
    ScrollToTop();
}
export const changeTime = (val) => {
    queryArgs.purchaseTimeStart = val && FormatterDateTime(val[0], "yyyy-MM-dd hh:mm:ss") || "";
    queryArgs.purchaseTimeEnd = val && FormatterDateTime(val[1], "yyyy-MM-dd hh:mm:ss") || "";
};
export const changeAfterSaleTime = (val) => {
    queryArgs.afterSalesTimeStart = val && FormatterDateTime(val[0], "yyyy-MM-dd hh:mm:ss") || "";
    queryArgs.afterSalesTimeEnd = val && FormatterDateTime(val[1], "yyyy-MM-dd hh:mm:ss") || "";
};
export function formatAfterSaleTime(row) {
    return FormatterDateTime(row.afterServiceTime, "yyyy-MM-dd hh:mm:ss") || '-';
}
export function formatPurchaseTime(row) {
    return FormatterDateTime(row.purchaseTime, "yyyy-MM-dd hh:mm:ss") || '-';
}
export const headerClick = async (column) => {
    if (await headerSortClick(column)) {
        queryArgs.orderType = column.prop == 'purchaseTime' ? OrderType.AfterSalePurchaseTime : OrderType.AfterSaleApplyTime;
        queryArgs.sortDirection = sortDirection[column.order] * 1;
        queryArgs.page = 1;
        queryData();
    }
};
// 查看凭证
export const AfterSalceImgs = reactive([""]);
export const showImagePre = ref(false);
export const clickAfterSalceImgs = (row) => {
    if (row.evidenceImages.length == 0)
        return;
    const temp = row.evidenceImages;
    AfterSalceImgs.splice(0, AfterSalceImgs.length);
    temp.forEach((img) => {
        AfterSalceImgs.push(img);
    });
    showImagePre.value = true;
};

import { defineComponent, onMounted, ref } from "vue";
export default defineComponent({
    props: {
        showImagePre: {
            type: Boolean,
            default() {
                return false;
            },
        },
        imgs: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    emits: ["close"],
    setup(props, { emit }) {
        const carousel = ref(null);
        const showImageDialog = ref(false);
        const curIndex = ref(0);
        const link = ref("");
        onMounted(() => {
            showImageDialog.value = props.showImagePre;
        });
        const closePre = () => {
            emit("close");
        };
        const clickPre = (index) => {
            carousel.value.setActiveItem(index + "");
        };
        const changePre = (index) => {
            curIndex.value = index;
        };
        const showNewLink = (href) => {
            link.value = href;
        };
        const openNewLink = () => {
            window.open(link.value);
        };
        const hideNewLink = () => {
            link.value = "";
        };
        return {
            link,
            carousel,
            showImageDialog,
            curIndex,
            closePre,
            clickPre,
            changePre,
            showNewLink,
            openNewLink,
            hideNewLink,
        };
    },
});
